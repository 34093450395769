/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

// import theme from './modules/theme';

// import user from './modules/user';
// import advertiser from './modules/advertiser';
// import admin from './modules/admin';
// import campaigncreation from './modules/campaign-creation';

// import agent from './modules/agent';
// import agency from './modules/agency';

// import createPersistedState from 'vuex-persistedstate';


Vue.use(Vuex)

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  //   modules: {
  //   user: user,
  //     theme: theme,
  //   advertiser: advertiser,
  //   admin: admin,
  //   agent: agent,
  //   campaigncreation: campaigncreation,
  //   agency: agency
  // },
    plugins: [
    // createPersistedState({})
  ],
    strict: process.env.NODE_ENV !== 'production'
})


/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import "firebase/compat/firestore";

import axios from 'axios';
// const INTERVIEW_BASE_URL = 'http://localhost:8080/video-interview/'
// const functionsURL = 'https://us-central1-teamo-staging.cloudfunctions.net/'

const actions = {
  //User Functions
  async createNewUser({ }, user) {
    let {email, password} ={...user} 
    let newuser = await firebase.auth().createUserWithEmailAndPassword(email, password).catch(function (error) {
      console.log('err', error)
      var errorCode = error.code;
      var errorMessage = error.message;
      return error
    });
    console.log('New User Created', newuser.user.uid)
    return newuser.user.uid
  },
  async fetchUserAccountStatus({commit },id) {
    return new Promise(async (resolve, reject) => {
      await firebase
        .firestore()
        .collection(`accountStatus`)
        .doc(id)
        .get()
        .then(snap => {
          console.log('snap',snap)
          if (snap.exists) {
            let data = snap.data();
            commit('setUserAccountStatus', data)
            resolve(data);
          } else {
            reject({message: "No such account exists"})
          }
        })
        .catch(err => {
          reject(err)
          console.log('error', err);
        });
    })
  },
  async createUserProfileinFS({ }, user) {
    return new Promise(async (resolve, reject) => {
      delete user.password
      let uid = await firebase.auth().currentUser.uid;
      await firebase
        .firestore()
        .collection('userProfile')
        .doc(uid)
        .set(user);
      resolve(true);
    });
  },
  async createStripeCustomer({ }, user) {
    return new Promise(async (resolve, reject) => {
      await axios.post(`${process.env.VUE_APP_functionsURL}stripeFunctions-createStripeCustomer/`, user).then(res => {
          res = res.data;
          resolve(res.id);
      }).catch(err => {
        reject(err)
      })
    });
  },
  async setUserAccountStatusinFS({ }, user) {
    return new Promise(async (resolve, reject) => {
      await axios.post(`${process.env.VUE_APP_functionsURL}userFunctions-setInitialUserAccountStatus/`, user).then(res => {
          res = res.data;
          resolve(res.id);
      }).catch(err => {
        reject(err)
      })
    });
  },
  async getAccountStorage({ }) {
    return new Promise(async (resolve, reject) => {
      await axios.post(`${process.env.VUE_APP_functionsURL}userFunctions-getAccountStorageStatus/`, {}).then(res => {
          res = res.data;
          resolve(res);
      }).catch(err => {
        reject(err)
      })
    });
  },
  async cancelCustomerSubscription() {
    return new Promise(async (resolve, reject) => {
      await axios.post(`${process.env.VUE_APP_functionsURL}stripeFunctions-cancelCustomerSubscription/`).then(res => {
          console.log('subscription cancelled', res)
          res = res.data;
          resolve(res);
      }).catch(err => {
        reject(err)
      })
    });
  },
  async signInAnonymously({ dispatch }) {
      await firebase
        .auth()
        .signInAnonymously()
        .catch(function (error) { });
    return
  },
  waitForUserLoad({ commit, state }) {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged(async res => {
        if (res?.uid) {
          commit('setEmail', res.email);
          commit('setUser', res.uid);
          commit('setStatus', 'success');
          commit('setError', null);
          resolve(res);
        } else {
          resolve(false);
        }
      });
    });
  },
  signOutAction({ commit }) {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(response => {
          commit('setUser', null);
          commit('setUserType', null);
          commit('setStatus', 'success');
          commit('setError', null);
          commit('setUserObject', null);
          commit('setUserAccountStatus', {})
          commit('setEmail', null);
          resolve();
        })
        .catch(error => {
          commit('setStatus', 'failure');
          commit('setError', error.message);
          reject();
        });
    });
  },
  async getUserAccount({ commit, state }, payload) {
    return new Promise(async (resolve, reject) => {
      let uid = await firebase.auth()?.currentUser?.uid;
      await firebase
        .firestore()
        .collection('userProfile')
        .doc(uid)
        .get()
        .then(snap => {
          if (snap.exists) {
            let user = snap.data();
            commit('setUserObject', user)
            resolve(user);
          } else {
            resolve({});
          }
        })
        .catch(err => {
          console.log('error', err);
        });
    });
  },
  async checkCustomClaim({ commit }) {
    return new Promise((resolve, reject) => {
      if (firebase.auth().currentUser) {
        firebase
          .auth()
          .currentUser?.getIdTokenResult()
          .then(idTokenResult => {
            commit('setUserType', 'Agent');
            let claims = idTokenResult?.claims;
            resolve(idTokenResult?.claims);
          })
          .catch(error => {
            console.log(error);
          });
      }
    });
  },
  async signInAction({ commit, dispatch }, payload) {
    return new Promise(async (resolve, reject) => {
      await firebase
        .auth()
        .signInWithEmailAndPassword(payload.email, payload.password)
        .then(response => {
          dispatch('getUserAccount')
          commit('setUser', response.user.uid);
          commit('setStatus', 'success');
          commit('setError', null);
          resolve(response.user);
        })
        .catch(error => {
          console.log('failed');
          commit('setStatus', 'failure');
          commit('setError', error.message);
          reject(error);
        });
    });
  },
  async getFireaseIdToken() {
    try {
      const token = await firebase.auth().currentUser.getIdToken();
      return token;
    } catch (err) {
      reject(err);
    }
  },
  async updateUserAccount({ commit, state }, data) {
    return new Promise(async (resolve, reject) => {
      let uid = firebase.auth().currentUser.uid;
      await firebase
        .firestore()
        .collection('userProfile')
        .doc(uid)
        .set(data);
      resolve(true);
    });
  },
  async addToEmailList({ commit, state }, data) {
    return new Promise(async (resolve, reject) => {
      await firebase
        .firestore()
        .collection('emailList')
        .add(data)
      resolve(true);
    });
  },
  async checkCouponCode({},id) {
    let code = await firebase
        .firestore()
        .collection('couponCodes')
        .doc(id)
      .get()
    console.log(code, 'coupon code')
    if (code.exists) {
      return true
    }
    return false
  },
  fetchPaymentHistory({ }) {
    return new Promise(async (resolve, reject) => {
      let uid = firebase.auth().currentUser.uid;
      await firebase
        .firestore()
        .collection('stripePayments')
        .doc(uid)
        .get()
        .then(snap => {
          if (snap.exists) {
            let user = snap.data();
            let docs = []
            Object.keys(user).map(id => {
              let obj = {
                date: user[id].date,
                amount_paid: user[id].amount_paid,
                hosted_invoice_url: user[id].hosted_invoice_url
              }
              docs.push(obj)
            })
            resolve(docs);
          } else {
            resolve([]);
          }
        })
        .catch(err => {
          console.log('error', err);
        });
    });
  },
  // async fetchAnnouncement() {
  //   return new Promise(async (resolve, reject) => {
  //     let uid = firebase.auth().currentUser.uid;
  //     await firebase
  //       .firestore()
  //       .collection(`advertiserAnnouncements`)
  //       .doc(uid)
  //       .get()
  //       .then(snap => {
  //         if (snap.exists) {
  //           let ann = snap.data();
  //           resolve(ann);
  //         } else {
  //           resolve({});
  //         }
  //       })
  //       .catch(err => {
  //         console.log('error', err);
  //       });
  //   })
  // },
    // /////////////////////////////////////////////
    // COMPONENTS
    // /////////////////////////////////////////////

    // Vertical NavMenu
    updateVerticalNavMenuWidth({ commit }, width) {
      commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
    },

    // VxAutoSuggest
    updateStarredPage({ commit }, payload) {
      commit('UPDATE_STARRED_PAGE', payload)
    },

    // The Navbar
    arrangeStarredPagesLimited({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_LIMITED', list)
    },
    arrangeStarredPagesMore({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_MORE', list)
    },

    // /////////////////////////////////////////////
    // UI
    // /////////////////////////////////////////////

    toggleContentOverlay({ commit }) {
      commit('TOGGLE_CONTENT_OVERLAY')
    },
    updateTheme({ commit }, val) {
      commit('UPDATE_THEME', val)
    },

    // /////////////////////////////////////////////
    // User/Account
    // /////////////////////////////////////////////

    updateUserInfo({ commit }, payload) {
      commit('UPDATE_USER_INFO', payload)
    },

  //   async createAdvertiserCampaign({ commit, state }) {
  //   return new Promise(async (resolve, reject) => {
  //     let uid = firebase.auth().currentUser.uid;
  //     let data = {
  //       ...state.basicdetails,
  //       ...state.targeting,
  //       ...state.creatives,
  //       ...state.budget
  //     };
  //     data.advertiserId = uid;
  //     console.log('data', data);
  //     await firebase
  //       .firestore()
  //       .collection('advertiserCampaigns')
  //       .add(data);
  //     resolve(true);
  //   });
  // },
    
  //Jobs Functions
  async fetchAllJobs({ commit, state, dispatch }) {
    return new Promise(async (resolve, reject) => {
      let uid = firebase.auth().currentUser.uid;
      const data = await firebase
        .firestore()
        .collection('jobs')
        .where('userId', '==', uid)
        .get();
      console.log('data', data)
      if (data.empty) {
        resolve([]);
      }
      let index = 1
      const documents = [];
      data.forEach(async item => {
        const document = item.data();
        document['id'] = item.id;
        let candidates = []
        //fetching candidates
        let candidatesObject = await firebase
          .firestore()
          .collection('candidates')
          .where('jobId', '==', item.id)
          .get();
        candidatesObject.forEach(obj => {
          const item = obj.data();
          item['id'] = obj.id;
          candidates.push(item)
        })
        document['candidates'] = candidates;
        documents.push(document);
        if (index >= data.size) {
          commit('setJobs', documents);
          resolve(documents);
        }
        index++
      });
    });
  }, 
  async fetchJobCandidates({}, id) {
    return new Promise(async (resolve, reject) => {
      const data = await firebase
        .firestore()
        .collection('candidates')
        .where('jobId', '==', id)
        .get();
      let index = 1
      const documents = [];
      data.forEach(async item => {
        const document = item.data();
        document['id'] = item.id;
        documents.push(document);
        index++
        if (index >= data.size) {
          resolve(documents);
        }
      })
    });
  }, 
  async fetchJob({ },id) {
    return new Promise(async (resolve, reject) => {
      await firebase
        .firestore()
        .collection(`jobs`)
        .doc(id)
        .get()
        .then(snap => {
          if (snap.exists) {
            let data = snap.data();
            resolve(data);
          } else {
            reject({message: "No such job exists"})
          }
        })
        .catch(err => {
          reject(err)
          console.log('error', err);
        });
    })
  },
  async checkInterviewLinkAuthenticity({ },id) {
    return new Promise(async (resolve, reject) => {
      await firebase
        .firestore()
        .collection(`candidates`)
        .doc(id)
        .get()
        .then(snap => {
          if (snap.exists) {
            let data = snap.data();
            resolve(data);
          } else {
            reject({message: "No such link exists"})
          }
        })
        .catch(err => {
          reject(err)
          console.log('error', err);
        });
    })
  },
  async updateInterviewLink({ commit, state }, data) {
    return new Promise(async (resolve, reject) => {
      let status = {status: data.status}
      await firebase
        .firestore()
        .collection('candidates')
        .doc(data.id)
        .update(status);
      resolve(true);
    });
  },
  async updateCandidate({ commit, state }, data) {
    return new Promise(async (resolve, reject) => {
      let id = data.id
      delete data.id
      await firebase
        .firestore()
        .collection('candidates')
        .doc(id)
        .update(data);
      resolve(true);
    });
  },
  async editJob({ commit, state }, data) {
    return new Promise(async (resolve, reject) => {
      let id = data.id 
      delete data.id
      await firebase
        .firestore()
        .collection('jobs')
        .doc(id)
        .set(data);
      resolve(true);
    });
  },

  async deleteJob({ commit, state }, id) {
    return new Promise(async (resolve, reject) => {
      await firebase
        .firestore()
        .collection('jobs')
        .doc(id)
        .delete();
      resolve(true);
    });
  },
  
  async createNewJob({ commit, state }, data) {
    return new Promise(async (resolve, reject) => {
      let uid = firebase.auth().currentUser.uid;
      data.userId = uid
      await firebase
        .firestore()
        .collection('jobs')
        .add(data);
      resolve(true);
    });
  },
  async removeJob({ commit, state }, id) {
    return new Promise(async (resolve, reject) => {
      await firebase
        .firestore()
        .collection('jobs')
        .delete(id);
      resolve(true);
    });
  },
  async addNewCandidate({ commit, state }, data) {
    return new Promise(async (resolve, reject) => {
      data.status = 'pending'
      let link = ''
      console.log('dta', data)
      let candidate = await firebase
        .firestore()
        .collection('candidates')
        .add(data);
      if (candidate.id) {
       data.link = `${process.env.VUE_APP_INTERVIEW_BASE_URL}${data.jobId}/${candidate.id}`
        await firebase
          .firestore()
          .collection('candidates')
          .doc(candidate.id)
          .update({link: data.link});
      }
      data.id = candidate.id
      console.log('new candidate created', candidate)
      resolve(data);
    });
  },
  async removeCandidate({ }, id) {
    await firebase
          .firestore()
          .collection('candidates')
          .doc(id)
      .delete();
    return true
  },
  async sendInterviewEmailToCandidate({}, data) {
    return new Promise(async (resolve, reject) => {
      await axios.post(`${process.env.VUE_APP_functionsURL}interviewFunctions-sendInterviewLinkToCandidate/`, data).then(res => {
          res = res.data;
          resolve(res);
      }).catch(err => {
        reject(err)
      })
    });
  },
  async fetchInterviewCompanyLogo({}, id) {
    return new Promise(async (resolve, reject) => {
      await axios.post(`${process.env.VUE_APP_functionsURL}userFunctions-getInterviewLogo/`, {jobId:id}).then(res => {
          res = res.data;
          resolve(res);
      }).catch(err => {
        reject(err)
      })
    });
  },
  async updateJobSize({}, id) {
    return new Promise(async (resolve, reject) => {
      await axios.post(`${process.env.VUE_APP_functionsURL}interviewFunctions-updateJobFolderSize/`, {id}).then(res => {
          res = res.data;
          resolve(res);
      }).catch(err => {
        reject(err)
      })
    });
  },
  async submitVideoInterview({ commit, state }, data) {
    return new Promise(async (resolve, reject) => {
      let id = data.id
      delete data.id
      await firebase
        .firestore()
        .collection('candidates')
        .doc(id)
        .update(data);
      resolve(true);
    });
  },
  async fetchVideoInterview({ },id) {
    return new Promise(async (resolve, reject) => {
      await firebase
        .firestore()
        .collection(`candidates`)
        .doc(id)
        .get()
        .then(snap => {
          if (snap.exists) {
            let data = snap.data();
            resolve(data);
          } else {
            reject({message: "No such link exists"})
          }
        })
        .catch(err => {
          reject(err)
          console.log('error', err);
        });
    })
  },
}

export default actions

/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(Vuesax)

import Toaster from "v-toaster";
Vue.use(Toaster)

// axios

import vueSmoothScroll from 'vue2-smooth-scroll'
Vue.use(vueSmoothScroll)

// Theme Configurations
import '../themeConfig.js'


// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css'


// Vue Router
import router from './router'


// Vuex Store
import store from './store/store'


// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)


// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'


// Feather font icon
require('./assets/css/iconfont.css')
import VuePlaceAutocomplete from 'vue-place-autocomplete';

Vue.use(VuePlaceAutocomplete);

import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);
// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

import axios from "./axios.js"
Vue.prototype.$http = axios

Vue.config.productionTip = false

console.log(process.env.NODE_ENV, 'env')

if (process.env.VUE_APP_ENV == 'production') {
  const script = document.createElement('script');
  script.innerHTML = `window['_fs_debug'] = false;
      window['_fs_host'] = 'fullstory.com';
      window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
      window['_fs_org'] = 'o-1RGN2F-na1';
      window['_fs_namespace'] = 'FS';
      (function (m, n, e, t, l, o, g, y) {
        if (e in m) { if (m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].'); } return; }
        g = m[e] = function (a, b, s) { g.q ? g.q.push([a, b, s]) : g._api(a, b, s); }; g.q = [];
        o = n.createElement(t); o.async = 1; o.crossOrigin = 'anonymous'; o.src = 'https://' + _fs_script;
        y = n.getElementsByTagName(t)[0]; y.parentNode.insertBefore(o, y);
        g.identify = function (i, v, s) { g(l, { uid: i }, s); if (v) g(l, v, s) }; g.setUserVars = function (v, s) { g(l, v, s) }; g.event = function (i, v, s) { g('event', { n: i, p: v }, s) };
        g.anonymize = function () { g.identify(!!0) };
        g.shutdown = function () { g("rec", !1) }; g.restart = function () { g("rec", !0) };
        g.log = function (a, b) { g("log", [a, b]) };
        g.consent = function (a) { g("consent", !arguments.length || a) };
        g.identifyAccount = function (i, v) { o = 'account'; v = v || {}; v.acctId = i; g(o, v) };
        g.clearUserCookie = function () { };
        g._w = {}; y = 'XMLHttpRequest'; g._w[y] = m[y]; y = 'fetch'; g._w[y] = m[y];
        if (m[y]) m[y] = function () { return g._w[y].apply(this, arguments) };
        g._v = "1.2.0";
      })(window, document, window['_fs_namespace'], 'script', 'user');`
  document.head.appendChild(script);
}

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

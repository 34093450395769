// axios
import axios from 'axios'
import firebase from 'firebase/compat/app';
const baseURL = ""

export default axios.create({
  baseURL: baseURL
  // You can add your headers here
})

axios.interceptors.request.use(
  async config => {
    if (firebase.auth().currentUser) {
      let token = await firebase.auth().currentUser.getIdToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    } else {
      return config;
    }
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error.response);
  }
);

// const VUE_APP_NODE_ENV= 'staging'
// const VUE_APP_apiKey= 'AIzaSyD76A3m79UkcKCDjCOrJ--uLUdKsstCvYs'
// const VUE_APP_authDomain= 'surf-web-staging.firebaseapp.com'
// const VUE_APP_databaseURL= 'https://surf-web-staging.firebaseio.com'
// const VUE_APP_projectId= 'surf-web-staging'
// const VUE_APP_storageBucket= 'surf-web-staging.appspot.com'
// const VUE_APP_messagingSenderId= '530137636137'
// const VUE_APP_firebaseFuncUrl=  'https://us-central1-surf-web-staging.cloudfunctions.net/advertiser-'
// const VUE_APP_firebaseAgencyFuncUrl = 'https://us-central1-surf-web-staging.cloudfunctions.net/agency-'
// const  VUE_APP_BQ_API='https://staging-bq-api.ridewithsurf.com:3000/api/'
// const VUE_APP_MAPS_API_KEY= 'AIzaSyDM1G3JPjbD7NSDZxZYeiWGs0jORxt4M-4'
// const VUE_APP_targetOptionDMA = '4iG6hJhfSwih4zRv5-A6Gw'
// const VUE_APP_targetOptionCreatives = "MsBOxdvnRHabeYO6bhTYUA"
// const VUE_APP_targetOptionPOI = 'drsrFcJpS96-wKZw5jDiIw'
// const VUE_APP_targetOptionZipcode = 'S2ud1YCJSn-3ab4Qwtj69g'
// var config = {
//   apiKey: process.env.VUE_APP_apiKey,
//   authDomain: process.env.VUE_APP_authDomain,
//   databaseURL: process.env.VUE_APP_databaseURL,
//   projectId: process.env.VUE_APP_projectId,
//   storageBucket: process.env.VUE_APP_storageBucket,
//   appId: process.env.VUE_APP_appId,
//   measurementId: process.env.VUE_APP_measurementId,
//   messagingSenderId: process.env.VUE_APP_messagingSenderId
// };
// console.log('config firebase', config)
// // Replace this with your own config details



const config = {
  apiKey: "AIzaSyCreDSBINOHYffR-J19sBdaJD1dl2cUUYM",
  authDomain: "autoscreen-app.firebaseapp.com",
  databaseURL: "https://autoscreen-app.firebaseio.com",
  projectId: "autoscreen-app",
  storageBucket: "autoscreen-app.appspot.com",
  messagingSenderId: "908115939447",
  appId: "1:908115939447:web:d5e172dd37d7e9cc333672",
  measurementId: "G-GBQT0RHC8C"
};
firebase.initializeApp(config);

/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import store from './store/store';

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [

        {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
            path: '/dashboard',
            component: () => import('./layouts/main/Main.vue'),
             redirect: '/dashboard/jobs',
            children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
                {
                  path: 'jobs',
                  name: 'jobs',
                  component: () => import('@/views/pages/jobs/Jobs.vue')
                },
                {
                  path: 'jobs/:id',
                  name: 'singleJob',
                  component: () => import('@/views/pages/jobs/SingleJob.vue')
              },
                 {
                  path: 'edit-job/:id',
                  name: 'editJob',
                  component: () => import('@/views/pages/jobs/EditJob.vue')
              },
                {
                  path: 'video-interview/:id',
                  name: 'video-interview-watch',
                  component: () => import('@/views/pages/interview/Interview.vue')
                } ,
                {
                  path: 'account',
                  name: 'account',
                  component: () => import('@/views/pages/account/Account.vue')
                },
                {
                  path: 'support',
                  name: 'support',
                  component: () => import('@/views/pages/support/Support.vue')
                },
                {
                  path: 'payment-info',
                  name: 'payment',
                  component: () => import('@/views/pages/payment/Payment.vue')
                },
            ],
        },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
        {
            path: '',
            component: () => import('@/layouts/full-page/FullPage.vue'),
            children: [
        // =============================================================================
        // PAGES
        // =============================================================================
              {
                path: '',
                name: 'landing-page',
                component: () => import('@/views/landing/LandingPage.vue')
              },
              {
                path: '/terms-of-service',
                name: 'terms-of-service',
                component: () => import('@/views/pages/legal/TOS.vue')
              },
              {
                path: '/privacy-policy',
                name: 'privacy-policy',
                component: () => import('@/views/pages/legal/Policy.vue')
              },
              {
                path: '/refund',
                name: 'refund',
                component: () => import('@/views/pages/legal/Refund.vue')
              },
              {
                  path: 'shared-video-interview/:id',
                  name: 'video-interview-shared',
                  component: () => import('@/views/pages/interview/SharedInterview.vue')
                } ,
              {
                path: '/video-interview/:jid/:uid',
                name: 'video-interview',
                component: () => import('@/views/video/VideoTest.vue')
              },
              {
                path: '/video-interview/:jid',
                name: 'video-interview',
                component: () => import('@/views/video/VideoTest.vue')
              },
              {
                path: '/checkout',
                name: 'page-login',
                component: () => import('@/views/auth/checkout/Checkout.vue')
              },
              {
                path: '/login',
                name: 'page-login',
                component: () => import('@/views/pages/Login.vue')
              },

              {
                path: '/register',
                name: 'register',
                component: () => import('@/views/auth/signup/SignUp.vue')
              },
              
              {
                path: '/pages/error-404',
                name: 'page-error-404',
                component: () => import('@/views/pages/Error404.vue')
              },
            ]
        },
        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: '/pages/error-404'
        }
    ],
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = "none";
    }
})

router.beforeEach(async (to, from, next) => {
  let usertype = store.state.user;
  let routeRegex = to.path;
  console.log('user router id', usertype)
  if(routeRegex.includes('login') && !usertype) {
    next();
  }
  else if (routeRegex.includes('dashboard') && !usertype) {
    next('login');
  }
  next();
});

export default router
